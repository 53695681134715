import React, { useEffect, useState } from 'react'
import { ExternalRegistrationForm } from '../components/external-registration-form'
import Modal from '../components/modal'

export const Register = (): React.ReactElement => {
	const [showModal, setShowModal] = useState(false)
	const [formsAvailable, setFormsAvailable] = useState(false)

	useEffect(() => {
		const script = document.createElement('script')
		const load_script = new Promise(function (resolve, reject) {
			script.src = 'https://info01.dnb.com/js/forms2/js/forms2.min.js'
			script.async = true
			script.addEventListener('load', function () {
				resolve('success')
			})
			script.addEventListener('error', function (e) {
				reject(e)
			})

			document.body.appendChild(script)
		})
		load_script.then(() => setFormsAvailable(true))
		return () => {
			document.body.removeChild(script)
		}
	}, [])

	return (
		<>
			<section className="head bg-register" id="head">
				<div className="container-body">
					<div className="container-title"></div>
				</div>
			</section>
			<section className="section-white">
				<div className="container-body">
					<div className="rack-regis-double">
						<div className="card-regis-double regis-left">
							<h2>Register for Paid Access</h2>
							<p className="space">
								To access any D&B APIs today, you need to first purchase an API key: <br/>fill in the form
								below to get that process started and find out pricing.
							</p>
							{formsAvailable && <ExternalRegistrationForm formId={1652} />}
						</div>
						<div className="card-regis-double regis-right">
							<div>
								<h3>Looking for Free Access?</h3>
								<p>
									In {new Date().getFullYear()}, we will be launching an enhanced experience for developers – one where you can
									test without needing to purchase first. Sign up below to be the first to know when that
									happens.
								</p>
							</div>
							<div className="btn-regis">
								<button onClick={() => setShowModal(true)} className="btn-register-b">
									Sign up
								</button>
							</div>
							<div>
								<p>
									In the meantime, check out our{' '}
									<a
										href="https://directplus.documentation.dnb.com/html/pages/DplusIntroduction.html"
										target="_blank"
										rel="noreferrer"
										className="docu"
									>
										documentation
									</a>
									.
								</p>
							</div>
						</div>
					</div>
				</div>		
			</section>
			
			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<h2>Register for Free Access</h2>
				{formsAvailable && <ExternalRegistrationForm formId={1832} />}
			</Modal>
		</>
	)
}
